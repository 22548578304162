import promo_pic_1 from './../../../media/png/promo_pic_1.png';
import promo_pic_2 from './../../../media/jpg/promo_pic_2.jpg';
import promo_pic_3 from './../../../media/jpg/promo_pic_3.jpg';
import promo_pic_4 from './../../../media/jpg/promo_pic_4.jpg';

export const promo_list = [
    // {
    //     title: 'Brunner - Zabudowa z termobetonu',
    //     pic: promo_pic_1,
    //     old_price: 6754,
    //     new_price: 2700
    // },
    {
        title: 'Cadel - Kuchnia Kook 80 do zabudowy',
        pic: promo_pic_2,
        old_price: 10500,
        new_price: 7000,
        euro: false
    },
    {
        title: 'Laminox - piecyk na pellet Primula Air',
        pic: promo_pic_3,
        old_price: 1800,
        new_price: 1400,
        euro: true
    },
    // {
    //     title: 'Stovax - Riva Vision Medium 8600',
    //     pic: promo_pic_4,
    //     old_price: 7600,
    //     new_price: 4700
    // },
    {
        title: 'Portal Marmurowy - Roso Portugalo',
        pic: '',
        old_price: 12000,
        new_price: 4000,
        euro: false
    },
]